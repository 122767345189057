import { IonApp } from "@ionic/angular";

export interface CustomNavData extends INavData {
    children?: CustomNavData[];
}

export interface INavData {
    title?: string;
    url?: string;
    icon_type?: string;
    icon?: string;
    query?: {}
}

export const Admin: CustomNavData[] = [
    {
        title: 'Dashboard',
        url: '/home',
        icon: 'speedometer',
    },
    {
        title: 'Inquiry',
        url: '/inquiry',
        icon: 'document',
    },
    {
        title: 'Survey',
        url: '/survey',
        icon: 'documents',
    },
    {
        title: 'BOQ',
        url: '/rfq',
        icon: 'clipboard',
    },
    {
        title: 'Quotation',
        url: '/view-quotation',
        icon: 'clipboard',
    },
    {
        title: 'Customer',
        url: '/customer',
        icon: 'person',
    },
    {
        title: 'Projects',
        url: '/projects',
        icon: 'albums',
    },

    {
        title: 'Complains',
        url: '/complain',
        icon: 'chatbox-ellipses',
    },
    {
        title: 'Invoice',
        url: '/invoice',
        icon: 'copy',
    },
    {
        title: 'Payment',
        url: '/payment',
        icon: 'card',
    },
    {
        title: 'Inventory',
        url: '/inventory',
        icon: 'receipt',
        children: [
            {
                title: 'Purchase',
                url: '/purchase',
                icon: 'receipt', // Use your desired icon
            },

            {
                title: 'Make',
                url: '/make',
                icon: 'bookmarks',
            },
            {
                title: 'Category',
                url: '/category',
                icon: 'bookmark',
            },
            {
                title: 'Product',
                url: '/inventory',
                icon: 'receipt', // Use your desired icon
            },
            {
                title: 'Unit Type',
                url: '/unit-type',
                icon: 'receipt', // Use your desired icon
            },
            {
                title: 'Low Stock',
                url: '/low-stock',
                icon: 'receipt', // Use your desired icon
            },
            {
                title: 'Serial No.',
                url: '/serial-no',
                icon: 'bookmark', // Use your desired icon
            },

            // Add more submenu items as needed
        ],
    },
    {
        title: 'Supplier',
        url: '/supplier',
        icon: 'people',
    },
    {
        title: 'Staff',
        url: '/users',
        icon: 'people',
    },
    // {
    //     title: 'Roles',
    //     url: '/roles',
    //     icon: 'person-add',
    // },
    {
        title: 'Profile',
        url: '/profile',
        icon: 'person-circle',
    },
    {
        title: 'Change Password',
        url: '/change-password',
        icon: 'lock-closed',
    },
];

export const Accountant: CustomNavData[] = [
    {
        title: 'Dashboard',
        url: '/home',
        icon: 'speedometer',
    },
    {
        title: 'Inquiry',
        url: '/inquiry',
        icon: 'document',
    },
    {
        title: 'Survey',
        url: '/survey',
        icon: 'documents',
    },
    {
        title: 'BOQ',
        url: '/rfq',
        icon: 'clipboard',
    },
    {
        title: 'Quotation',
        url: '/view-quotation',
        icon: 'clipboard',
    },
    {
        title: 'Customer',
        url: '/customer',
        icon: 'person',
    },
    {
        title: 'Projects',
        url: '/projects',
        icon: 'albums',
    },
    {
        title: 'Complains',
        url: '/complain',
        icon: 'chatbox-ellipses',
    },

    {
        title: 'Invoice',
        url: '/invoice',
        icon: 'copy',
    },
    {
        title: 'Payment',
        url: '/payment',
        icon: 'card',
    },
    {
        title: 'Inventory',
        url: '/inventory',
        icon: 'receipt',
        children: [
            {
                title: 'Purchase',
                url: '/purchase',
                icon: 'receipt', // Use your desired icon
            },

            {
                title: 'Make',
                url: '/make',
                icon: 'bookmarks',
            },
            {
                title: 'Category',
                url: '/category',
                icon: 'bookmark',
            },
            {
                title: 'Product',
                url: '/inventory',
                icon: 'receipt', // Use your desired icon
            },
            {
                title: 'Unit Type',
                url: '/unit-type',
                icon: 'receipt', // Use your desired icon
            },
            {
                title: 'Low Stock',
                url: '/low-stock',
                icon: 'receipt', // Use your desired icon
            },
            {
                title: 'Serial No.',
                url: '/serial-no',
                icon: 'bookmark', // Use your desired icon
            },

            // Add more submenu items as needed
        ],
    },
    {
        title: 'Supplier',
        url: '/supplier',
        icon: 'people',
    },
    {
        title: 'Staff',
        url: '/users',
        icon: 'people',
    },


    {
        title: 'Profile',
        url: '/profile',
        icon: 'person-circle',
    },
    {
        title: 'Change Password',
        url: '/change-password',
        icon: 'lock-closed',
    },
];

export const Staff: INavData[] = [
    {
        title: 'Dashboard',
        url: '/home',
        icon: 'speedometer',
    },
    {
        title: 'Customer',
        url: '/customer',
        icon: 'person',
    },
    {
        title: 'Inquiry',
        url: '/inquiry',
        icon: 'document',
    },
    {
        title: 'Survey',
        url: '/survey',
        icon: 'documents',
    },
    {
        title: 'Complains',
        url: '/complain',
        icon: 'headset',
    },
    {
        title: 'Projects',
        url: '/projects',
        icon: 'albums',
    },
    {
        title: 'Profile',
        url: '/profile',
        icon: 'person-circle',
    },
    {
        title: 'Change Password',
        url: '/change-password',
        icon: 'lock-closed',
    },
];


