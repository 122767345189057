import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationService } from './services/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers';
import { MasterService } from './services/master.service';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { LoadingService } from './services/loading.service';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { AuthGuard } from './guards';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { DatePipe } from '@angular/common';
import { FileOpener } from '@ionic-native/file-opener/ngx';
// import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { FileUploadModule } from 'ng2-file-upload';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { NgxPrintModule } from 'ngx-print';
// import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { HTTP } from '@ionic-native/http/ngx';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { AwesomeCordovaNativePlugin } from '@awesome-cordova-plugins/core'
import { DecodeHtmlEntities, DecodeHtmlEntitiesModule } from 'decode-html-entities';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { IonicStorageModule } from '@ionic/storage';
// import { StorageService } from './services/storage.service';
import { FilePath } from '@ionic-native/file-path/ngx';
import { File } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Camera } from '@ionic-native/Camera/ngx';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FileUploadModule,
    NgxPrintModule,
    DecodeHtmlEntitiesModule,
    IonicStorageModule.forRoot(),
    Ng2SearchPipeModule,
    ModalModule.forRoot(),
  ],
  providers: [
    AndroidFullScreen,
    Keyboard,
    StatusBar,
    Network,
    SplashScreen,
    AuthenticationService,
    MasterService,
    LoadingService,
    AuthGuard,
    File,
    FileTransferObject, 
    FileTransfer,
    FileOpener,
    DatePipe,
    FilePath,
    FCM,
    HTTP,
    AwesomeCordovaNativePlugin,
    DecodeHtmlEntities,
    AndroidPermissions,
    Camera,
    WebView,
    Storage,
    ImagePicker,
    // StorageService,
    // DocumentViewer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
    
  bootstrap: [AppComponent],
})
export class AppModule {}
