import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
 
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./system/login/login.module').then( m => m.LoginPageModule)
  },
  {
    // path: 'approverfq',
    path: 'approverfq/:id/:id2/:id3',
    loadChildren: () => import('./system/approverfq/approverfq.module').then( m => m.ApproverfqPageModule)
  },
  {
    path: 'cust-post-installation',
    loadChildren: () => import('./system/cust-post-installation/cust-post-installation.module').then( m => m.CustPostInstallationPageModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./system/success/success.module').then( m => m.SuccessPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./system/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule),
  },
  {
    path: 'inquiry',
    loadChildren: () => import('./pages/inquiry/inquiry.module').then( m => m.InquiryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'complain',
    loadChildren: () => import('./pages/complain/complain.module').then( m => m.ComplainPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'rfq',
    loadChildren: () => import('./pages/rfq/rfq.module').then( m => m.RfqPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects.module').then( m => m.ProjectsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inventory',
    loadChildren: () => import('./pages/inventory/inventory.module').then( m => m.InventoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'installation',
    loadChildren: () => import('./pages/installation/installation.module').then( m => m.InstallationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    loadChildren: () => import('./system/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'roles',
    loadChildren: () => import('./pages/roles/roles.module').then( m => m.RolesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-users',
    loadChildren: () => import('./pages/add-users/add-users.module').then( m => m.AddUsersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-users',
    loadChildren: () => import('./pages/add-users/add-users.module').then( m => m.AddUsersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-inquiry',
    loadChildren: () => import('./pages/add-inquiry/add-inquiry.module').then( m => m.AddInquiryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-inquiry',
    loadChildren: () => import('./pages/add-inquiry/add-inquiry.module').then( m => m.AddInquiryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-quotation',
    loadChildren: () => import('./pages/edit-quotation/edit-quotation.module').then( m => m.EditQuotationPageModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'add-rfq',
    loadChildren: () => import('./pages/add-rfq/add-rfq.module').then( m => m.AddRfqPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'edit-rfq',
  //   loadChildren: () => import('./pages/add-rfq/add-rfq.module').then( m => m.AddRfqPageModule)
  // },
  {
    path: 'add-complain',
    loadChildren: () => import('./pages/add-complain/add-complain.module').then( m => m.AddComplainPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-complain',
    loadChildren: () => import('./pages/add-complain/add-complain.module').then( m => m.AddComplainPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customer',
    loadChildren: () => import('./pages/customer/customer.module').then( m => m.CustomerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-customer',
    loadChildren: () => import('./pages/add-customer/add-customer.module').then( m => m.AddCustomerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-customer',
    loadChildren: () => import('./pages/add-customer/add-customer.module').then( m => m.AddCustomerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-inventory',
    loadChildren: () => import('./pages/add-inventory/add-inventory.module').then( m => m.AddInventoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-inventory',
    loadChildren: () => import('./pages/add-inventory/add-inventory.module').then( m => m.AddInventoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'no-internet',
    loadChildren: () => import('./pages/no-internet/no-internet.module').then( m => m.NoInternetPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-project',
    loadChildren: () => import('./pages/view-project/view-project.module').then( m => m.ViewProjectPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'issue-item/:rfq_id',
    loadChildren: () => import('./pages/issue-item/issue-item.module').then( m => m.IssueItemPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'create-survey',
    loadChildren: () => import('./pages/create-survey/create-survey.module').then( m => m.CreateSurveyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-survey',
    loadChildren: () => import('./pages/create-survey/create-survey.module').then( m => m.CreateSurveyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-cust-post-installation',
    loadChildren: () => import('./pages/add-cust-post-installation/add-cust-post-installation.module').then( m => m.AddCustPostInstallationPageModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'edit-cust-post-installation',
    loadChildren: () => import('./pages/add-cust-post-installation/add-cust-post-installation.module').then( m => m.AddCustPostInstallationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-project-checklist',
    loadChildren: () => import('./pages/add-project-checklist/add-project-checklist.module').then( m => m.AddProjectChecklistPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-project-checklist',
    loadChildren: () => import('./pages/add-project-checklist/add-project-checklist.module').then( m => m.AddProjectChecklistPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-work-completion',
    loadChildren: () => import('./pages/add-work-completion/add-work-completion.module').then( m => m.AddWorkCompletionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-work-completion',
    loadChildren: () => import('./pages/add-work-completion/add-work-completion.module').then( m => m.AddWorkCompletionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-cust-post-installation',
    loadChildren: () => import('./pages/view-cust-post-installation/view-cust-post-installation.module').then( m => m.ViewCustPostInstallationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-work-completion',
    loadChildren: () => import('./pages/view-work-completion/view-work-completion.module').then( m => m.ViewWorkCompletionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-project-checklist',
    loadChildren: () => import('./pages/view-project-checklist/view-project-checklist.module').then( m => m.ViewProjectChecklistPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-rfq',
    loadChildren: () => import('./pages/view-rfq/view-rfq.module').then( m => m.ViewRfqPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-survey',
    loadChildren: () => import('./pages/view-survey/view-survey.module').then( m => m.ViewSurveyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-member',
    loadChildren: () => import('./pages/add-member/add-member.module').then( m => m.AddMemberPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-inquiry',
    loadChildren: () => import('./pages/view-inquiry/view-inquiry.module').then( m => m.ViewInquiryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-complain',
    loadChildren: () => import('./pages/view-complain/view-complain.module').then( m => m.ViewComplainPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-users',
    loadChildren: () => import('./pages/view-users/view-users.module').then( m => m.ViewUsersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-customer',
    loadChildren: () => import('./pages/view-customer/view-customer.module').then( m => m.ViewCustomerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-rfq',
    loadChildren: () => import('./pages/edit-rfq/edit-rfq.module').then( m => m.EditRfqPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'survey',
    loadChildren: () => import('./pages/survey/survey.module').then( m => m.SurveyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'new-existing-inquiry',
    loadChildren: () => import('./pages/new-existing-inquiry/new-existing-inquiry.module').then( m => m.NewExistingInquiryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'addsurveyform',
    loadChildren: () => import('./pages/addsurveyform/addsurveyform.module').then( m => m.AddsurveyformPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quotation',
    loadChildren: () => import('./pages/quotation/quotation.module').then( m => m.QuotationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'make',
    loadChildren: () => import('./pages/make/make.module').then( m => m.MakePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-purchase',
    loadChildren: () => import('./pages/add-purchase/add-purchase.module').then( m => m.AddPurchasePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice',
    loadChildren: () => import('./pages/invoice/invoice.module').then( m => m.InvoicePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'purchase',
    loadChildren: () => import('./pages/purchase/purchase.module').then( m => m.PurchasePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-purchase',
    loadChildren: () => import('./pages/view-purchase/view-purchase.module').then( m => m.ViewPurchasePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-invoice',
    loadChildren: () => import('./pages/view-invoice/view-invoice.module').then( m => m.ViewInvoicePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'supplier',
    loadChildren: () => import('./pages/supplier/supplier.module').then( m => m.SupplierPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-supplier',
    loadChildren: () => import('./pages/add-supplier/add-supplier.module').then( m => m.AddSupplierPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-supplier',
    loadChildren: () => import('./pages/add-supplier/add-supplier.module').then( m => m.AddSupplierPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-supplier/:id',
    loadChildren: () => import('./pages/view-supplier/view-supplier.module').then( m => m.ViewSupplierPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-invoice',
    loadChildren: () => import('./pages/add-invoice/add-invoice.module').then( m => m.AddInvoicePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-invoice',
    loadChildren: () => import('./pages/add-invoice/add-invoice.module').then( m => m.AddInvoicePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-payment/:id',
    loadChildren: () => import('./pages/view-payment/view-payment.module').then( m => m.ViewPaymentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-payment',
    loadChildren: () => import('./pages/add-payment/add-payment.module').then( m => m.AddPaymentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-payment',
    loadChildren: () => import('./pages/add-payment/add-payment.module').then( m => m.AddPaymentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-invoice-payment',
    loadChildren: () => import('./pages/add-invoice-payment/add-invoice-payment.module').then( m => m.AddInvoicePaymentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-comment/:id',
    loadChildren: () => import('./pages/add-comment/add-comment.module').then( m => m.AddCommentPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unit-type',
    loadChildren: () => import('./pages/unit-type/unit-type.module').then( m => m.UnitTypePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-unit-type',
    loadChildren: () => import('./pages/add-unit-type/add-unit-type.module').then( m => m.AddUnitTypePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-unit-type',
    loadChildren: () => import('./pages/add-unit-type/add-unit-type.module').then( m => m.AddUnitTypePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'low-stock',
    loadChildren: () => import('./pages/low-stock/low-stock.module').then( m => m.LowStockPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-quotation',
    loadChildren: () => import('./pages/view-quotation/view-quotation.module').then( m => m.ViewQuotationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-inventory',
    loadChildren: () => import('./pages/view-inventory/view-inventory.module').then( m => m.ViewInventoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-quotation',
    loadChildren: () => import('./pages/add-quotation/add-quotation.module').then( m => m.AddQuotationPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'serial-no',
    loadChildren: () => import('./pages/serial-no/serial-no.module').then( m => m.SerialNoPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'edit-payment',
  //   loadChildren: () => import('./pages/edit-payment/edit-payment.module').then( m => m.EditPaymentPageModule)
  // },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
