import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataPassService {

  private customerId: string;

  constructor() {
   }

  getCustomerId(): string {
    return this.customerId;
  }

  setCustomerId(customerId: string): void {
    this.customerId = customerId;
    localStorage.setItem('customerId', customerId);
  }

}
