import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appConfig } from '../app.config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient,private toastr: ToastrService) { }

    login(data) {
        return this.http.post<any>(appConfig.apiUrl + '/login', data)
            .pipe(map(res => {

                if (res.status == 1) {
                    var user = res.data;
                    // login successful if there's a jwt token in the response
                    if (user && user.user_token) {
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        localStorage.setItem('userType', JSON.stringify(user.user_role));
                        localStorage.setItem('token', JSON.stringify(user.user_token));
                        localStorage.setItem('user_type', JSON.stringify(user.user_type));
                    }
                    return res;
                } else {
                    this.toastr.error(res.message, 'warning');
                }

            }));
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        const helper = new JwtHelperService();
        return !helper.isTokenExpired(token);
    }

    public isLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user_type');
        localStorage.removeItem('rfqId');
        localStorage.removeItem('userType');
        localStorage.removeItem('project_id');
        localStorage.removeItem('device_token');
        localStorage.removeItem('notificationData');
        localStorage.removeItem('no-internet');
        localStorage.removeItem('inquiryId');
        localStorage.removeItem('customerId');
        localStorage.removeItem('custPostId');
        localStorage.removeItem('checkListId');
        localStorage.removeItem('workCompletionId');
        localStorage.removeItem('user_type');
        localStorage.removeItem('token');
        // localStorage.clear();
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user_type');
        localStorage.removeItem('rfqId');
        localStorage.removeItem('userType');
        localStorage.removeItem('project_id');
        localStorage.removeItem('device_token');
        localStorage.removeItem('notificationData');
        localStorage.removeItem('no-internet');
        localStorage.removeItem('inquiryId');
        localStorage.removeItem('customerId');
        localStorage.removeItem('custPostId');
        localStorage.removeItem('checkListId');
        localStorage.removeItem('workCompletionId');
        localStorage.removeItem('user_type');
        localStorage.removeItem('token');
    }
}


