import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators'

@Injectable()
export class MasterService {
    constructor(private http: HttpClient) { }

    getLoggedinUser() {
        return this.http.get<any>(appConfig.apiUrl + '/isLoggedin')
            .pipe(map(res => {
                return res;
            }));
    }
    
    getAllUsers() {
        return this.http.get<any>(appConfig.apiUrl + '/allusers')
            .pipe(map(res => {
                return res;
            }));
    }

    getAllUsersPOST(data) {
        return this.http.post<any>(appConfig.apiUrl + '/allusers', data)
            .pipe(map(res => {
                return res;
            }));
    }
    

    getAllEngineer(data) {
        return this.http.post<any>(appConfig.apiUrl + '/getengineers_toassing', data)
            .pipe(map(res => {
                return res;
            }));
    }
    
    getUserToProject(data) {
        return this.http.post<any>(appConfig.apiUrl + '/userslist_to_add', data)
            .pipe(map(res => {
                return res;
            }));
    }


    forgot_password(data) {
        return this.http.post<any>(appConfig.apiUrl + '/forgetpassword', data)
            .pipe(map(res => {
                return res;
            }));
    }

    changepassword(user) {
        return this.http.post<any>(appConfig.apiUrl + '/changepassword', user)
            .pipe(map(res => {
                return res;
            }));
    }

    profile(data) {
        return this.http.post<any>(appConfig.apiUrl + '/profile', data)
            .pipe(map(res => {
                return res;
            }));
    }

    boqData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/boqs', data)
            .pipe(map(res => {
                return res;
            }));
    }

    quotationsList(data) {
        return this.http.post<any>(appConfig.apiUrl + '/quotations_list', data)
            .pipe(map(res => {
                return res;
            }));
    }

    projectData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/projects', data)
            .pipe(map(res => {
                return res;
            }));
    }

    ComplainData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/complaints', data)
            .pipe(map(res => {
                return res;
            }));
    }

    InventoryData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/inventories', data)
            .pipe(map(res => {
                return res;
            }));
    }

    InstallationData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/installations', data)
            .pipe(map(res => {
                return res;
            }));
    }

    InquiriesData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/inquiries', data)
            .pipe(map(res => {
                return res;
            }));
    }

    rolesData() {
        return this.http.get<any>(appConfig.apiUrl + '/allroles')
            .pipe(map(res => {
                return res;
            }));
    }

    updateProfileData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editprofile', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addUser(data) {
        return this.http.post<any>(appConfig.apiUrl + '/adduser', data)
            .pipe(map(res => {
                return res;
            }));
    }

    
    addMember(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addprojectmembers', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addEvent(data) {
        return this.http.post<any>(appConfig.apiUrl + '/saveevent', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateUser(data) {
        return this.http.post<any>(appConfig.apiUrl + '/edituser', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteUser(data): Observable<any> {
        return this.http.post<any>(appConfig.apiUrl + '/delete_user_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteSurvey(data): Observable<any> {
        return this.http.post<any>(appConfig.apiUrl + '/delete_survey_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteMember(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_teammebmer_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addRole(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addrole', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addInquiry(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addinquiry', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateInquiry(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editinquiry', data)
            .pipe(map(res => {
                return res;
            }));
    }

    additionalAddBoq(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addnewadditionalrfq', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addBoq(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addrfq_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateBoq(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editrfq_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getBoqDataById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewrfq', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addInventory(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addinventory', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateInventory(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editinventory', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addComplain(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addcomplaint', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateComplain(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editcomplaint', data)
            .pipe(map(res => {
                return res;
            }));
    }
    
    MakesData() {
        return this.http.get<any>(appConfig.apiUrl + '/allmakes')
            .pipe(map(res => {
                return res;
            }));
    }

    postMakesData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/allmakes', data)
            .pipe(map(res => {
                return res;
            }));
    }

    CategoryData() {
        return this.http.get<any>(appConfig.apiUrl + '/allcategory')
            .pipe(map(res => {
                return res;
            }));
    }

    CategoryDatabyId(data) {
        return this.http.get<any>(appConfig.apiUrl + '/allcategory', { params: { make_id: data } } )
            .pipe(map(res => {
                return res;
            }));
    }

    PostCategoryData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/allcategory', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getItemData() {
        return this.http.get<any>(appConfig.apiUrl + '/allitems')
            .pipe(map(res => {
                return res;
            }));
    }


    itemData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/allitems', data)
            .pipe(map(res => {
                return res;
            }));
    }

    StoreData() {
        return this.http.get<any>(appConfig.apiUrl + '/allstore_type')
            .pipe(map(res => {
                return res;
            }));
    }

    
    AllUnitData() {
        return this.http.get<any>(appConfig.apiUrl + '/allunit_type')
            .pipe(map(res => {
                return res;
            }));
    }


    getUserById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewuser', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getInventoryById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewinventory', data)
            .pipe(map(res => {
                return res;
            }));
    }

    createProject(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addproject_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    customerData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/customers', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getcustomerData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/customers_list', data)
            .pipe(map(res => {
                return res;
            }));
    }

    customerswithproject(data) {
        return this.http.post<any>(appConfig.apiUrl + '/customerswithproject', data)
            .pipe(map(res => {
                return res;
            }));
    }

    nonsurvey_inquiries(data) {
        return this.http.post<any>(appConfig.apiUrl + '/nonsurvey_inquiries', data)
            .pipe(map(res => {
                return res;
            }));
    }


    deleteCustomer(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_customer', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getCustomerById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewcustomer', data)
            .pipe(map(res => {
                return res;
            }));
    }

    createCustomer(data) {
        return this.http.post<any>(appConfig.apiUrl + '/createcustomer', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateCustomer(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editcustomer', data)
            .pipe(map(res => {
                return res;
            }));
    }

    InquiryDataById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewinquiry', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteInquiry(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_inquiry_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    restoreInquiry(data) {
        return this.http.post<any>(appConfig.apiUrl + '/restore_inquiry', data)
            .pipe(map(res => {
                return res;
            }));
    }

    restoreSurvey(data) {
        return this.http.post<any>(appConfig.apiUrl + '/restore_survey', data)
            .pipe(map(res => {
                return res;
            }));
    }

    restoreBoq(data) {
        return this.http.post<any>(appConfig.apiUrl + '/restore_rfq', data)
            .pipe(map(res => {
                return res;
            }));
    }

    restoreComplain(data) {
        return this.http.post<any>(appConfig.apiUrl + '/restore_complain', data)
            .pipe(map(res => {
                return res;
            }));
    }

    restoreUser(data) {
        return this.http.post<any>(appConfig.apiUrl + '/restore_user', data)
            .pipe(map(res => {
                return res;
            }));
    }

    restoreProject(data) {
        return this.http.post<any>(appConfig.apiUrl + '/restore_project', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteComplain(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_complaint_new', data)
            .pipe(map(res => {
                return res;
            }));
    }
    
    ComplainDataById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewcomplaint_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addMake(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addmake', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addCate(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addcategory', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addStock(data) {
        return this.http.post<any>(appConfig.apiUrl + '/updatestocks', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteMake(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_make_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteInventory(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_inventory_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteCate(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_category_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateMake(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editmake', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateCate(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editcategory', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteProject(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_project_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    projectDataById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewproject', data)
            .pipe(map(res => {
                return res;
            }));
    }

    issueItem(data) {
        return this.http.post<any>(appConfig.apiUrl + '/issueitems', data)
            .pipe(map(res => {
                return res;
            }));
    }

    issueItemNew(data) {
        return this.http.post<any>(appConfig.apiUrl + '/issueitems_new_dev', data)
            .pipe(map(res => {
                return res;
            }));
    }

    IssueItemData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewissueitems', data)
            .pipe(map(res => {
                return res;
            }));
    }

    
    viewIssueItemData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewissueitems_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteRole(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_role', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateRole(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editrole', data)
            .pipe(map(res => {
                return res;
            }));
    }

    DataRolesById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewrole', data)
            .pipe(map(res => {
                return res;
            }));
    }

    DashboardData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/dashboard_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    createSurvey(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addsurvey_new_dev', data)
            .pipe(map(res => {
                return res;
            }));
    }

    additionalcreateSurvey(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addnewadditionalsurvey', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateSurvey(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editsurvey_new_dev', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getSurveyById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewsurvey', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getSurveyByIdnew(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewsurvey_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteRfq(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_rfq_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getinstallationDataById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewcustpostinstallation', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addCustPostinstallation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addcustpostinstallation', data)
            .pipe(map(res => {
                return res;
            }));
    }

    editCustPostinstallation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editcustpostinstallation', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addChecklist(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addprojectchecklist', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateChecklist(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editprojectchecklist', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getChecklistDataById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewprojectchecklist', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addWorkCompletionForm(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addworkcompletionnhandover', data)
            .pipe(map(res => {
                return res;
            }));
    }

    updateWorkCompletionForm(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editworkcompletionnhandover', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getWorkCompletionDataById(data) {
        return this.http.post<any>(appConfig.apiUrl + '/vieworkcompletionnhandover', data)
            .pipe(map(res => {
                return res;
            }));
    }

    SurveysData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/survey', data)
            .pipe(map(res => {
                return res;
            }));
    }

    avalablity_status(data) {
        return this.http.post<any>(appConfig.apiUrl + '/enable_disable_survey_editing', data)
          .pipe(map(res => {
            return res;
          }));
      }
      
      getProjectByCustomerId(data) {
        return this.http.post<any>(appConfig.apiUrl + '/projectsbycustomer', data)
          .pipe(map(res => {
            return res;
          }));
      }
      
      viewquotation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewquotation', data)
            .pipe(map(res => {
                return res;
            }));
    }

    viewquotationPublic(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewquotationPublic', data)
            .pipe(map(res => {
                return res;
            }));
    }

    submit_rfq_forapproval(data) {
        return this.http.post<any>(appConfig.apiUrl + '/submit_rfq_forapproval', data)
            .pipe(map(res => {
                return res;
            }));
    }

    approveQuotation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/approve_quotation', data)
            .pipe(map(res => {
                return res;
            }));
    }

    approve_rfq(data) {
        return this.http.post<any>(appConfig.apiUrl + '/approve_rfq', data)
            .pipe(map(res => {
                return res;
            }));
    }
      

    addquotation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addquotation', data)
            .pipe(map(res => {
                return res;
            }));
    }

    
    markProject(data) {
        return this.http.post<any>(appConfig.apiUrl + '/change_project_status', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getAllNotification(data) {
        return this.http.post<any>(appConfig.apiUrl + '/getNotifications', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deleteDocImg(data) {
        return this.http.post<any>(appConfig.apiUrl + '/removeprojectimage', data)
            .pipe(map(res => {
                return res;
            }));
    }

    removeNotification(data) {
        return this.http.post<any>(appConfig.apiUrl + '/deleteNotification', data)
            .pipe(map(res => {
                return res;
            }));
    }

    clearNotifiction(data) {
        return this.http.post<any>(appConfig.apiUrl + '/clearNotification', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addpurchase(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addpurchase', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getPurchase(data) {
        return this.http.post<any>(appConfig.apiUrl + '/listpurchase_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addSerialNumber(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addserial_number', data)
            .pipe(map(res => {
                return res;
            }));
    }

    viewpurchase(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewpurchase_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    serialnowisedata(data) {
        return this.http.post<any>(appConfig.apiUrl + '/searchby_serial_number', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addsupplier(data) {
        return this.http.post<any>(appConfig.apiUrl + '/addsupplier', data)
            .pipe(map(res => {
                return res;
            }));
    }

    listsupplier(data) {
        return this.http.post<any>(appConfig.apiUrl + '/listsupplier', data)
            .pipe(map(res => {
                return res;
            }));
    }
    getlistsupplier(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_listsupplier', data)
            .pipe(map(res => {
                return res;
            }));
    }
    editsupplier(data) {
        return this.http.post<any>(appConfig.apiUrl + '/editsupplier', data)
            .pipe(map(res => {
                return res;
            }));
    }
    viewsupplier(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewsupplier', data)
            .pipe(map(res => {
                return res;
            }));
    }
    deletesupplier(data) {
        return this.http.post<any>(appConfig.apiUrl + '/deletesupplier', data)
            .pipe(map(res => {
                return res;
            }));
    }
    viewIssueitems(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewissueitems_new', data)
            .pipe(map(res => {
                return res;
            }));
    }
    bomIssueItems(data) {
        return this.http.post<any>(appConfig.apiUrl + '/bomissueitems', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getInvoiceList(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_invoice_list', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getInvoiceDetails(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_invoice_details', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getProjectList(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_project_list', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getBoqList(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_boq_details', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addInvoice(data) {
        return this.http.post<any>(appConfig.apiUrl + '/add_invoice', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getInvoicePaymentList(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_invoice_payment_list', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addPayment(data) {
        return this.http.post<any>(appConfig.apiUrl + '/add_payment', data)
            .pipe(map(res => {
                return res;
            }));
    }
    manageCustomerStatusPost(data) {
        return this.http.post<any>(appConfig.apiUrl + '/manage_customer_status', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getInvoiceListNew(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_invoice_list_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    editPayment(data) {
        return this.http.post<any>(appConfig.apiUrl + '/edit_payment', data)
            .pipe(map(res => {
                return res;
            }));
    }

    viewPayment(data) {
        return this.http.post<any>(appConfig.apiUrl + '/view_payment', data)
            .pipe(map(res => {
                return res;
            }));
    }

    deletPayment(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_payment', data)
            .pipe(map(res => {
                return res;
            }));
    }

    addComplaintComment(data) {
        return this.http.post<any>(appConfig.apiUrl + '/add_complaint_comment', data)
            .pipe(map(res => {
                return res;
            }));
    }

    viewComplaintNew(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewcomplaint_new', data)
            .pipe(map(res => {
                return res;
            }));
    }
    addUnitType(data) {
        return this.http.post<any>(appConfig.apiUrl + '/add_unit_type', data)
            .pipe(map(res => {
                return res;
            }));
    }
    editUnitType(data) {
        return this.http.post<any>(appConfig.apiUrl + '/edit_unit_type', data)
            .pipe(map(res => {
                return res;
            }));
    }
    deleteUnitType(data) {
        return this.http.post<any>(appConfig.apiUrl + '/delete_unit_type', data)
            .pipe(map(res => {
                return res;
            }));
    }
    getUnitType(data) {
        return this.http.post<any>(appConfig.apiUrl + '/list_unit_type', data)
            .pipe(map(res => {
                return res;
            }));
    }
    viewUnitTypebyId(data) {
        return this.http.post<any>(appConfig.apiUrl + '/view_unit_type', data)
            .pipe(map(res => {
                return res;
            }));
    }
    complaintCommentStatus(data) {
        return this.http.post<any>(appConfig.apiUrl + '/manage_complaints_comments_status', data)
            .pipe(map(res => {
                return res;
            }));
    }

    listLowStock(data) {
        return this.http.post<any>(appConfig.apiUrl + '/list_low_stock', data)
            .pipe(map(res => {
                return res;
            }));
    }
    searchSerialNumber(data) {
        return this.http.post<any>(appConfig.apiUrl + '/search_serial_number', data)
            .pipe(map(res => {
                return res;
            }));
    }

    viewinventoryNew(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewinventory_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    assignWorkCompletion(data) {
        return this.http.post<any>(appConfig.apiUrl + '/assign_work_completion', data)
            .pipe(map(res => {
                return res;
            }));
    }

    itemselectedData(data) {
        return this.http.post<any>(appConfig.apiUrl + '/allitems_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    purchaseItems(data) {
        return this.http.post<any>(appConfig.apiUrl + '/purchase_items', data)
            .pipe(map(res => {
                return res;
            }));
    }

    surveyStatusChange(data) {
        return this.http.post<any>(appConfig.apiUrl + '/survey_status_change', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getGraphDetail(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_graph_detail', data)
            .pipe(map(res => {
                return res;
            }));
    }

    viewpurchaseNew(data) {
        return this.http.post<any>(appConfig.apiUrl + '/viewpurchase_new', data)
            .pipe(map(res => {
                return res;
            }));
    }

    approvedQuotation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/approve_quotation', data)
            .pipe(map(res => {
                return res;
            }));
    }

    getListsupplier(data) {
        return this.http.post<any>(appConfig.apiUrl + '/get_listsupplier', data)
            .pipe(map(res => {
                return res;
            }));
    }


}
