import { Injectable } from '@angular/core'; import { Subject } from 'rxjs';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  header_class = '';
  _loading
  selected_lang = ''
  private fooSubject = new Subject<any>();

  constructor(public toastController: ToastController,
     public loadingcontroller: LoadingController,
      public alertController: AlertController,
       public router: Router,
        public activatedRoute: ActivatedRoute,
         public translate: TranslateService,
          private _sanitizer: DomSanitizer) { }


  async present(data) {
    this._loading = await this.loadingcontroller.create({
      message: this.translate.instant(data)
    });
    await this._loading.present();

  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  numDigits(x) {
    return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
  }


  safe_url(link) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(link);
  }
  
  async dismiss() {
    return await this.loadingcontroller.dismiss();
  }


  async alert(header, message) {
    const alert = await this.alertController.create({
      header: this.translate.instant(header),
      message: this.translate.instant(message),
    });
    await alert.present();
  }

  publishSomeData(data: any) {
    this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }


  logScrolling(data) {
    if (data.detail.scrollTop > 50) {
      this.header_class = 'affix'
    } else {
      this.header_class = ''
    }
  }

  async toast(msg, type) {
    const toast = await this.toastController.create({
      message: this.translate.instant(msg),
      duration: 2000,
      position: "top",
      color: type,
      cssClass: 'cus_toast'
    });
    toast.present();
  }

}
