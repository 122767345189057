import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  getMenuData() {
    return [
      {
        title: 'Dashboard',
        url: '/home',
        icon: 'home',
        subMenu: [],
      },
      {
        title: 'BOQ',
        url: '/rfq',
        icon: 'clipboard',
        subMenu: [],
    },
      {
        title: 'Inventory',
        icon: 'list',
        subMenu: [
          { title: 'Purchase', url: '/purchase' },
          { title: 'Make', url: '/make' },
          { title: 'Category', url: '/category' },
        ],
      },
    ];
  }
}
